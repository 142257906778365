import { LOADING_STATE } from '../../constants';

export const initialState = {
  fetchTransactionsLoading: LOADING_STATE.IDLE,
  fetchTransactionsSuccess: [],
  fetchTransactionsError: null,
  pagination: {
    totalPages: 0,
    totalElements: 0,
  },
}
