import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '../../constants';
import { fetchMeters, searchMeters } from '../actions/meterActions';
import { initialState } from '../reducers/accountReducer';

export const meterSlice = createSlice({
  name: 'meterSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeters.pending, (state) => {
        if (state.fetchMetersLoading === LOADING_STATE.IDLE) {
          state.fetchMetersLoading = LOADING_STATE.PEDNING
          state.fetchMetersError = null
          state.fetchMetersSuccess = []
        }
      })
      .addCase(fetchMeters.fulfilled, (state, action) => {
        state.fetchMetersLoading = LOADING_STATE.IDLE
        state.fetchMetersError = null
        const { content, ...rest } = action.payload.meters
        state.fetchMetersSuccess = content
        state.pagination = rest
      })
      .addCase(fetchMeters.rejected, (state, action) => {
        state.fetchMetersLoading = LOADING_STATE.IDLE
        state.fetchMetersSuccess = []
        state.fetchMetersError = action.payload
      })
      .addCase(searchMeters.pending, (state) => {
        if (state.searchMetersLoading === LOADING_STATE.IDLE) {
          state.searchMetersLoading = LOADING_STATE.PEDNING
          state.searchMetersError = null
          state.searchMetersSuccess = []
        }
      })
      .addCase(searchMeters.fulfilled, (state, action) => {
        state.searchMetersLoading = LOADING_STATE.IDLE
        state.searchMetersError = null
        const { content, ...rest } = action.payload.meters
        state.searchMetersSuccess = content
        state.searchPagination = rest
      })
      .addCase(searchMeters.rejected, (state, action) => {
        state.searchMetersLoading = LOADING_STATE.IDLE
        state.searchMetersSuccess = []
        state.searchMetersError = action.payload
      })
  },
})

export default meterSlice.reducer
