import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from '../../constants'
import { fetchIpp } from '../actions/ippActions'
import { initialState } from '../reducers/ippReducer'

export const ippSlice = createSlice({
  name: 'ippSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIpp.pending, (state) => {
        if (state.fetchIPPLoading === LOADING_STATE.IDLE) {
          state.fetchIPPLoading = LOADING_STATE.PEDNING
          state.fetchIPPError = null
          state.fetchIPPSuccess = []
        }
      })
      .addCase(fetchIpp.fulfilled, (state, action) => {
        state.fetchIPPLoading = LOADING_STATE.IDLE
        state.fetchIPPError = null
        const { content, ...rest } = action.payload.ipp
        state.fetchIPPSuccess = content
        state.pagination = rest
      })
      .addCase(fetchIpp.rejected, (state, action) => {
        state.fetchIPPLoading = LOADING_STATE.IDLE
        state.fetchIPPSuccess = []
        state.fetchIPPError = action.payload
      })
  },
})

export default ippSlice.reducer
