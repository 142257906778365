import { LOADING_STATE } from '../../constants';

export const initialState = {
  fetchNotificationsLoading: LOADING_STATE.IDLE,
  fetchNotificationsSuccess: [],
  fetchNotificationsError: null,
  searchNotificationsLoading: LOADING_STATE.IDLE,
  searchNotificationsSuccess: [],
  searchNotificationsError: null,
  searchPagination: {
    totalPages: 0,
    totalElements: 0,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
  },
}
