import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from '../../constants'
import { fetchNotifications, searchNotifications } from '../actions/notificationActions';
import { initialState } from '../reducers/notificationsReducer'

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        if (state.fetchNotificationsLoading === LOADING_STATE.IDLE) {
          state.fetchNotificationsLoading = LOADING_STATE.PEDNING
          state.fetchNotificationsError = null
          state.fetchNotificationsSuccess = []
        }
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.fetchNotificationsLoading = LOADING_STATE.IDLE
        state.fetchNotificationsError = null
        const { content, ...rest } = action.payload.notifications
        state.fetchNotificationsSuccess = content
        state.pagination = rest
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.fetchNotificationsLoading = LOADING_STATE.IDLE
        state.fetchNotificationsSuccess = []
        state.fetchNotificationsError = action.payload
      })

      .addCase(searchNotifications.pending, (state) => {
        if (state.searchNotificationsLoading === LOADING_STATE.IDLE) {
          state.searchNotificationsLoading = LOADING_STATE.PEDNING
          state.searchNotificationsError = null
          state.searchNotificationsSuccess = []
        }
      })
      .addCase(searchNotifications.fulfilled, (state, action) => {
        state.searchNotificationsLoading = LOADING_STATE.IDLE
        state.searchNotificationsError = null
        const { content, ...rest } = action.payload.notifications
        state.searchNotificationsSuccess = content
        state.searchPagination = rest
      })
      .addCase(searchNotifications.rejected, (state, action) => {
        state.searchNotificationsLoading = LOADING_STATE.IDLE
        state.searchNotificationsSuccess = []
        state.searchNotificationsError = action.payload
      })
  },
})

export default notificationSlice.reducer
