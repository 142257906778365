import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const createUser = createAsyncThunk('auth/create',  async (payload, { rejectWithValue }) => {
    try {
      const response = await APIService.post('/auth/create', { ...payload })
      return { user: response.data.data, message: response.data.message }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
})

export const fetchUsers = createAsyncThunk('user', async ({ pageNumber, pageSize, pageable = true }, { rejectWithValue }) => {
    try {
      const response = await APIService.get( `/user?pageNumber=${pageNumber}&pageSize=${pageSize}&pageable=${pageable}`);
      return { user: response.data.data, message: response.data.message }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
})

export const editUser = createAsyncThunk('user/edit', async ({ userId, ...rest }, { rejectWithValue }) => {
    try {
      const response = await APIService.put( `/user/${userId}`, {...rest})
      return { user: response.data.data, message: response.data.message }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
})

export const resendVerification = createAsyncThunk('/auth/email/resend/email', async ({ email }, { rejectWithValue }) => {
  try {
    const response = await APIService.post( `/auth/email/resend/${email}`)
    return { user: response.data.data, message: response.data.message }
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const searchUserByEmail = createAsyncThunk('/user/email/', async ({ email }, { rejectWithValue }) => {
  try {
    const response = await APIService.get( `/user/${email}`)
    return { user: response.data.data, message: response.data.message }
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})
