import { LOADING_STATE } from '../../constants';

export const initialState = {
  fetchAccountsLoading: LOADING_STATE.IDLE,
  fetchAccountsSuccess: [],
  fetchAccountsError: null,
  searchAccountsLoading: LOADING_STATE.IDLE,
  searchAccountsSuccess: [],
  searchAccountsError: null,
  searchPagination: {
    totalPages: 0,
    totalElements: 0,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
  },
}
