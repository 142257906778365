import { fetchDashboardReports } from '../actions/dashboardActions';
import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '../../constants';
import { initialState } from '../reducers/dashboardReducer';

export const reportSlice = createSlice({
  name: 'dashboardReportSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardReports.pending, (state) => {
        if (state.fetchDashboardReportsLoading === LOADING_STATE.IDLE) {
          state.fetchDashboardReportsLoading = LOADING_STATE.PEDNING
          state.fetchDashboardReportsError = null
          state.fetchDashboardReportsSuccess = {}
        }
      })
      .addCase(fetchDashboardReports.fulfilled, (state, action) => {
        state.fetchDashboardReportsLoading = LOADING_STATE.IDLE
        state.fetchDashboardReportsError = null
        const { reports } = action.payload
        state.fetchDashboardReportsSuccess = reports
      })
      .addCase(fetchDashboardReports.rejected, (state, action) => {
        state.fetchDashboardReportsLoading = LOADING_STATE.IDLE
        state.fetchDashboardReportsSuccess = {}
        state.fetchDashboardReportsError = action.payload
      })
  },
})

export default reportSlice.reducer
