import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const fetchAccounts = createAsyncThunk('accounts', async ({ pageNumber, pageSize, pageable = true }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(`/account?pageNumber=${pageNumber}&pageSize=${pageSize}&pageable=${pageable}`,)
      return { accounts: response.data.data, message: 'Accounts successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const searchAccounts = createAsyncThunk('accounts/search', async ({ accountNumber }, { rejectWithValue }) => {
    try {
      const response = await APIService.get( `/account/${accountNumber}`,)
      return { accounts: response.data.data, message: 'Accounts successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
 