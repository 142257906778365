import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const fetchNotifications = createAsyncThunk('notifications', async ({ pageNumber, pageSize, dateRange }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(
        `/account/notifications?pageNumber=${pageNumber}&pageSize=${pageSize}&from=${dateRange.from}&to=${dateRange.to}`,
      )
      return { notifications: response.data.data, message: 'Notifications successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const searchNotifications = createAsyncThunk('notifications/search', async ({ accountNumber, dateRangeSearch, pageSizeSearch }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(`/account/notifications/${accountNumber}?from=${dateRangeSearch.from}&to=${dateRangeSearch.to}&pageSize=${pageSizeSearch}`)
      return { notifications: response.data.data, message: 'Notifications successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
