import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from '../../constants'
import { fetchReports, searchReportsByMeterNo } from '../actions/reportsActions'
import { initialState } from '../reducers/reportsReducers'

export const reportSlice = createSlice({
  name: 'reportSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state) => {
        if (state.fetchReportsLoading === LOADING_STATE.IDLE) {
          state.fetchReportsLoading = LOADING_STATE.PEDNING
          state.fetchReportsError = null
          state.fetchReportsSuccess = []
        }
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.fetchReportsLoading = LOADING_STATE.IDLE
        state.fetchReportsError = null
        const { content, ...rest } = action.payload.reports
        state.fetchReportsSuccess = content
        state.pagination = rest
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.fetchReportsLoading = LOADING_STATE.IDLE
        state.fetchReportsSuccess = []
        state.fetchReportsError = action.payload
      })
      .addCase(searchReportsByMeterNo.pending, (state) => {
        state.searchByMeterNoLoading = LOADING_STATE.PEDNING
        state.searchByMeterNoEror = null
        state.searchByMeterNoSuccess = []
      })
      .addCase(searchReportsByMeterNo.fulfilled, (state, action) => {
        state.searchByMeterNoLoading = LOADING_STATE.IDLE
        state.searchByMeterNoEror = null
        const { content, ...rest } = action.payload.reports
        state.searchByMeterNoSuccess = content
        state.searchPagination = rest
      })
      .addCase(searchReportsByMeterNo.rejected, (state, action) => {
        state.searchByMeterNoLoading = LOADING_STATE.IDLE
        state.searchByMeterNoEror = action.payload
        state.searchByMeterNoSuccess = []
      })
  },
})

export default reportSlice.reducer
