import { createAsyncThunk } from '@reduxjs/toolkit';
import { APIService } from '../../config/http';

export const fetchMeters = createAsyncThunk('meters', async ({ pageNumber, pageSize, pageable = true }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(`/meter/all/?pageNumber=${pageNumber}&pageSize=${pageSize}&pageable=${pageable}`)
      return { meters: response.data.data, message: 'Meters successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const searchMeters = createAsyncThunk('meters/search', async ({ meterNumber }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(`/meter/all/${meterNumber}`)
      return { meters: response.data.data, message: 'Meters successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)