import { LOADING_STATE } from '../../constants';

export const initialState = {
  loading: LOADING_STATE.IDLE,
  user: null,
  error: null,
  users: [],
  editUserLoading: LOADING_STATE.IDLE,
  editUserSuccess: {},
  editUserError: null,
  editUserMessage: null,
  resendVerificationLoading: LOADING_STATE.IDLE,
  resendVerificationSuccess: {},
  resendVerificationError: null,
  searchUserByEmailLoading: LOADING_STATE.IDLE,
  searchUserByEmailSuccess: [],
  searchUserByEmailLoadingError: null,
  searchPagination: {
    totalPages: 0,
    totalElements: 0,
  },

  pagination: {
    totalPages: 0,
    totalElements: 0,
  },
}
