import { createSlice } from '@reduxjs/toolkit';
import { LOADING_STATE } from '../../constants';
import { fetchAccounts, searchAccounts } from '../actions/accountActions';
import { initialState } from '../reducers/accountReducer';

export const accountSlice = createSlice({
  name: 'accountSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        if (state.fetchAccountsLoading === LOADING_STATE.IDLE) {
          state.fetchAccountsLoading = LOADING_STATE.PEDNING
          state.fetchAccountsError = null
          state.fetchAccountsSuccess = []
        }
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.fetchAccountsLoading = LOADING_STATE.IDLE
        state.fetchAccountsError = null
        const { content, ...rest } = action.payload.accounts
        state.fetchAccountsSuccess = content
        state.pagination = rest
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.fetchAccountsLoading = LOADING_STATE.IDLE
        state.fetchAccountsSuccess = []
        state.fetchAccountsError = action.payload
      })

      .addCase(searchAccounts.pending, (state) => {
        if (state.searchAccountsLoading === LOADING_STATE.IDLE) {
          state.searchAccountsLoading = LOADING_STATE.PEDNING
          state.searchAccountsError = null
          state.searchAccountsSuccess = []
        }
      })
      .addCase(searchAccounts.fulfilled, (state, action) => {
        state.searchAccountsLoading = LOADING_STATE.IDLE
        state.searchAccountsError = null
        const { content, ...rest } = action.payload.accounts
        state.searchAccountsSuccess = content
        state.searchPagination = rest
      })
      .addCase(searchAccounts.rejected, (state, action) => {
        state.searchAccountsLoading = LOADING_STATE.IDLE
        state.searchAccountsSuccess = []
        state.searchAccountsError = action.payload
      })
  },
})

export default accountSlice.reducer
