import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const fetchTransactions = createAsyncThunk('transactions', async ({ accountId }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(`/account/transactions/${accountId}`)
      return { transactions: response.data.data, message: 'Transactions successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
