import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from '../../constants'
import { fetchPlants } from '../actions/plantActions'
import { initialState } from '../reducers/plantReducer'

export const plantSlice = createSlice({
  name: 'plantSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlants.pending, (state) => {
        if (state.fetchPlantsLoading === LOADING_STATE.IDLE) {
          state.fetchPlantsLoading = LOADING_STATE.PEDNING
          state.fetchPlantsError = null
          state.fetchPlantsSuccess = []
        }
      })
      .addCase(fetchPlants.fulfilled, (state, action) => {
        state.fetchPlantsLoading = LOADING_STATE.IDLE
        state.fetchPlantsError = null
        const { content, ...rest } = action.payload.plants
        state.fetchPlantsSuccess = content
        state.pagination = rest
      })
      .addCase(fetchPlants.rejected, (state, action) => {
        state.fetchPlantsLoading = LOADING_STATE.IDLE
        state.fetchPlantsSuccess = []
        state.fetchPlantsError = action.payload
      })
  },
})

export default plantSlice.reducer
