import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from '../../constants'
import { fetchTransactions } from '../actions/transactionActions'
import { initialState } from '../reducers/transactionReducer'

export const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        if (state.fetchTransactionsLoading === LOADING_STATE.IDLE) {
          state.fetchTransactionsLoading = LOADING_STATE.PEDNING
          state.fetchTransactionsError = null
          state.fetchTransactionsSuccess = []
        }
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.fetchTransactionsLoading = LOADING_STATE.IDLE
        state.fetchTransactionsError = null
        const { content, ...rest } = action.payload.transactions
        state.fetchTransactionsSuccess = content
        state.pagination = rest
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.fetchTransactionsLoading = LOADING_STATE.IDLE
        state.fetchTransactionsSuccess = []
        state.fetchTransactionsError = action.payload
      })
  },
})

export default transactionSlice.reducer
