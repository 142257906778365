import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const loginUser = createAsyncThunk(
  'auth/login',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await APIService.post('/auth/login', {
        ...payload,
      })
      localStorage.setItem('OsiomoJWTtoken', response.headers.authorization)
      return { user: response.data.data, message: 'Login successful' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const forgotPassword = createAsyncThunk(
  'auth/password/forgot/{email}',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await APIService.post(`/auth/password/forgot/${email}`)
      return { user: response.data.data, message: 'Reset email sent' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const resetPassword = createAsyncThunk('/auth/password/reset', async (payload, { rejectWithValue }) => {
    try {
      const response = await APIService.post('/auth/password/reset', payload)
      return { user: response.data.data, message: 'Reset email sent' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)