import { createAction, createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from '../../constants'
import { createUser, editUser, fetchUsers, resendVerification, searchUserByEmail } from '../actions/usersActions'
import { initialState } from '../reducers/usersReducer'

export const revertAll = createAction('REVERT_ALL')

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.searchUserByEmailSuccess = [];
  }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        if (state.loading === LOADING_STATE.IDLE) {
          state.loading = LOADING_STATE.PEDNING
          state.error = null
          state.user = null
        }
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = LOADING_STATE.IDLE
        state.user = action.payload
        state.error = null
      })

      .addCase(createUser.rejected, (state, action) => {
        state.loading = LOADING_STATE.IDLE
        state.error = action.payload
        state.user = null
      })
      .addCase(fetchUsers.pending, (state) => {
        if (state.loading === LOADING_STATE.IDLE) {
          state.loading = LOADING_STATE.PEDNING
          state.error = null
          state.users = []
        }
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = LOADING_STATE.IDLE
        state.error = null
        state.user = null
        const { content, ...rest } = action.payload.user
        state.users = content
        state.pagination = rest
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = LOADING_STATE.IDLE
        state.users = []
        state.error = action.payload
        state.user = null
      })
      .addCase(editUser.pending, (state) => {
        if (state.loading === LOADING_STATE.IDLE) {
          state.editUserLoading = LOADING_STATE.PEDNING
          state.editUserError = null
          state.editUserSuccess = {}
        }
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.editUserLoading = LOADING_STATE.IDLE
        state.editUserError = null
        state.editUserSuccess = action.payload.user
        state.editUserMessage = action.payload.message
      })
      .addCase(editUser.rejected, (state, action) => {
        state.editUserLoading = LOADING_STATE.IDLE
        state.editUserError = action.payload
        state.editUserSuccess = {}
      })
      .addCase(resendVerification.pending, (state) => {
        state.resendVerificationLoading = LOADING_STATE.PEDNING
        state.resendVerificationSuccess = {}
        state.resendVerificationError = null
      })
      .addCase(resendVerification.fulfilled, (state, action) => {
        state.resendVerificationLoading = LOADING_STATE.IDLE
        state.resendVerificationSuccess = action.payload
        state.resendVerificationError = null
      })
      .addCase(resendVerification.rejected, (state, action) => {
        state.resendVerificationLoading = LOADING_STATE.IDLE
        state.resendVerificationSuccess = {}
        state.resendVerificationError = action.payload
      })
      .addCase(searchUserByEmail.pending, (state) => {
        state.searchUserByEmailLoading = LOADING_STATE.PEDNING
        state.searchUserByEmailSuccess = []
        state.searchUserByEmailError = null
      })
      .addCase(searchUserByEmail.fulfilled, (state, action) => {
        state.searchUserByEmailLoading = LOADING_STATE.IDLE
        const { content, ...rest} = action.payload.user
        state.searchUserByEmailSuccess = content
        state.searchUserByEmailError = null
        state.searchPagination = rest
        
      })
      .addCase(searchUserByEmail.rejected, (state, action) => {
        state.searchUserByEmailLoading = LOADING_STATE.IDLE
        state.searchUserByEmailSuccess = []
        state.searchUserByEmailError = action.payload
      })
      .addCase(revertAll, () => initialState)
  },
})
export const { reset } = usersSlice.actions;
export default usersSlice.reducer
