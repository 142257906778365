import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const fetchDashboardReports = createAsyncThunk(
  'dashboard',
  async (_, { rejectWithValue }) => {
    try {
      const response = await APIService.get('/dashboard')
      return { reports: response.data.data, message: 'Dashboard Reports successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
