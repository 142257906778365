import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const fetchPlants = createAsyncThunk(
  'plants',
  async ({ plant, pageSize, pageNumber, dateRange }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(`/meter/plant/report/${plant}?pageSize=${pageSize}&pageNumber=${pageNumber}&from=${dateRange.from}&to=${dateRange.to}`)
      return { plants: response.data.data, message: 'Plants successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

