import { LOADING_STATE } from '../../constants'

export const initialState = {
  loading: LOADING_STATE.IDLE,
  auth: null,
  error: null,
  forgotPasswordLoading: LOADING_STATE.IDLE,
  forgotPasswordSuccess: {},
  forgotPasswordError: null,
  resetPasswordLoading: LOADING_STATE.IDLE,
  resetPasswordSuccess: {},
  resetPasswordError: null,
}

export const logOutReducer = (state) => {
  localStorage.clear()
  state.auth = null
}
