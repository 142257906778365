import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/usersSlice';
import authSlice from './slices/authSlice';
import accountSlice from './slices/accountSlices';
import transactionSlice from './slices/transactionsSlice';
import reportsSlice from './slices/reportsSlice';
import notificationSlice from './slices/notificationSlice';
import dashboardReportsSlice from './slices/dashboardSlices';
import meterReportsSlice from './slices/meterSlice';
import plantSlices from './slices/plantSlices';
import ippSlices from './slices/ippSlices';

export const store = configureStore({
  reducer: {
    userEntity: usersReducer,
    authEntity: authSlice,
    accountEntity: accountSlice,
    transactionEntity: transactionSlice,
    reportsEntity: reportsSlice,
    notificationsEntity: notificationSlice,
    dashboardEntity: dashboardReportsSlice,
    meterEntity: meterReportsSlice,
    plantEntity: plantSlices,
    ippEntity: ippSlices,
  },
})
