import { LOADING_STATE } from '../../constants';

export const initialState = {
  fetchIPPLoading: LOADING_STATE.IDLE,
  fetchIPPSuccess: [],
  fetchIPPError: null,
  pagination: {
    totalPages: 0,
    totalElements: 0,
  },
}