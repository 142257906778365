import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const fetchReports = createAsyncThunk(
  'reports',
  async ({ pageNumber, pageSize, dateRange }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(
        `/meter/vend/report?pageNumber=${pageNumber}&pageSize=${pageSize}&from=${dateRange.from}&to=${dateRange.to}`,
      )
      return { reports: response.data.data, message: 'Reports successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const searchReportsByMeterNo = createAsyncThunk(
  'reports/search',
  async ({ meterNo, pageSize, dateRange }, { rejectWithValue }) => {
    try {
      const response = await APIService.get(
        `/meter/vend/report/${meterNo}?pageSize=${pageSize}&from=${dateRange?.from}&to=${dateRange?.to}`,
      )
      return { reports: response.data.data, message: 'Reports successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

