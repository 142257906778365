import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from '../../constants'
import { loginUser, forgotPassword, resetPassword } from '../actions/authActions'
import { initialState, logOutReducer } from '../reducers/authReducer'

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    logOutUser: logOutReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = LOADING_STATE.PEDNING
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.auth = action.payload
        state.loading = LOADING_STATE.IDLE
        state.error = null
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = LOADING_STATE.IDLE
        state.error = action.payload
        state.auth = null
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = LOADING_STATE.PEDNING
        state.forgotPasswordSuccess = {}
        state.forgotPasswordError = null
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = LOADING_STATE.IDLE
        state.forgotPasswordSuccess = action.payload
        state.forgotPasswordError = null
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordError = action.payload
        state.forgotPasswordSuccess = {}
        state.forgotPasswordLoading = LOADING_STATE.IDLE
      })

      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordLoading = LOADING_STATE.PEDNING
        state.resetPasswordSuccess = {}
        state.resetPasswordError = null
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPasswordLoading = LOADING_STATE.IDLE
        state.resetPasswordSuccess = action.payload
        state.resetPasswordError = null
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordError = action.payload
        state.resetPasswordSuccess = {}
        state.resetPasswordLoading = LOADING_STATE.IDLE
      })
  },
})

export const { logOutUser } = authSlice.actions

export default authSlice.reducer
