import { Space, Spin } from 'antd';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';


const LayoutComponent = React.lazy(() => import('./layout'));
const Login = React.lazy(() => import('./pages/login'));
const NotFound = React.lazy(() => import('./pages/notFound'));
const ForgotPassword = React.lazy(() => import('./pages/forgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/resetPassword'));
const Users = React.lazy(() => import('./pages/users'));
const Accounts = React.lazy(() => import('./pages/accounts'));
const Notifications = React.lazy(() => import('./pages/notifications'));
const Reports = React.lazy(() => import('./pages/reports'));
const Dashboard = React.lazy(() => import('./pages/dashboard'));
const Meters = React.lazy(() => import('./pages/meters'));
const Plants = React.lazy(() => import('./pages/plants'));
const IppReport = React.lazy(() => import('./pages/ipp'));



const Loader = () => {
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '10%'
      }}
    >
      <Space>
        <Spin size="large">
          <div className="content" />
        </Spin>
      </Space>
    </Space>
  );
};

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route path="/dashboard" element={<LayoutComponent />}>
              <Route path="" element={<Dashboard />} />
              <Route path="accounts" element={<Accounts />} />
              <Route path="users" element={<Users />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="plants" element={<Plants />} />
              <Route path="ipp-reports" element={<IppReport />} />
              <Route path="reports" element={<Reports />} />
              <Route path="meters" element={<Meters />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
