import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIService } from '../../config/http'

export const fetchIpp = createAsyncThunk(
  'ipp',
  async ({ pageNumber, pageSize, dateRange, ipp }, { rejectWithValue }) => {
    console.log({ pageNumber, pageSize, dateRange, ipp })
    try {
      const response = await APIService.get(`/meter/ipp/report/${ipp}?pageNumber=${pageNumber}&pageSize=${pageSize}&from=${dateRange.from}&to=${dateRange.to}`)
      return { ipp: response.data.data, message: 'IPP successfully fetched' }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

