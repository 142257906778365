import { LOADING_STATE } from '../../constants';

export const initialState = {
  fetchReportsLoading: LOADING_STATE.IDLE,
  fetchReportsSuccess: [],
  fetchReportsError: null,
  searchByMeterNoLoading: LOADING_STATE.IDLE,
  searchByMeterNoSuccess: [],
  searchByMeterNoEror: null,
  searchPagination: {
    totalPages: 0,
    totalElements: 0,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
  },
}
